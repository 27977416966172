<template>
  <v-app>
    <Snackbar />
    <v-main>
      <v-container class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" class="d-flex flex-column align-center justify-center">
            <v-img src="@/assets/mangbagja500px.png" alt="" height="100" class="animate__animated animate__bounceIn" contain/>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <span class="subtitle-1 font-weight-light" >{{quote}}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-btn
      @click="logout()"
      class="ma-5"
      color="primary"
      fab
      dark
      absolute
      right
      title="keluar"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <dashboard-core-footer />
  </v-app>

</template>

<script>
import 'animate.css'
import router from '@/router'
import Snackbar from '@/components/common/Snackbar'
import axios from 'axios'
import _ from 'lodash'
import DashboardService from '@/services/DashboardService'
import SSOService from '@/services/SSOService'
import store from '@/store'
import {local} from '@/store/local'
export default {
  name:"default",
  components:{
    Snackbar,
    DashboardCoreFooter: () => import('@/components/core/Footer')
  },
  data(){
    return {
      quote:"Silahkan tunggu..."
    }
  },
  beforeMount() {
    // this.componentDidMount()
    // this.redirect()
  },
  beforeCreate(){
    // this.redirect()
  },
  created(){
    // this.redirect()
    // this.componentDidMount()
  },
  mounted(){
    this.redirect()
    // this.componentDidMount()
  },
  methods:{
    logout(){
      store.dispatch('user/logout')
    },
    redirect(){
      var API_URL = ''
      if (process.env.VUE_APP_SERVER === 'local'){
        API_URL = ''
      }else {
        API_URL = process.env.VUE_APP_API_URL
      }
      //Handle redirect SSO
      const queryString = window.location.href;
      const urlParams = new URL(queryString)
      // const ott = urlParams.searchParams.get('ott')
      const trimUrlParams = urlParams.hash.slice(2)
      const myArrayOTT = trimUrlParams.split("=")
      // const ott = myArrayOTT[1]
      const ott = myArrayOTT[1].split("&")[0]
      var tahun = myArrayOTT[2]
      if(ott != null){
        // let url_verify = API_URL+'/api/sso/auth/verify'
        // let url_peg = API_URL+'page1/pegawai'
        // alert(ott+' '+tahun+' '+url_verify)
        // let token_api = null
        // let tahun = null
        // let config = null
        try {
          var direct_token = null
          let url_verify = API_URL+'/api/sso/auth/verify'
          let url_peg = API_URL+'/api/page1/pegawai'
          let username = null
          var config_verify = {
            headers : {
              'Content-Type' : 'application/json'
            }
          }
          axios.post(url_verify,{ott: ott, tahun: tahun}, config_verify)
          .then(response => {
            let data = response.data
            response = data
            local.setLocal("login_via_ga", true)
            local.setLocal("_r", data.roles) // store roles in local
            // token_api = data.token
            username = data.username
            tahun = data.tahun
            direct_token = data.token
            var config = {
              headers : {
                'x-access-token' : data.token,
                'Content-Type' : 'application/json'
              }
            }
            console.log('Direct from SSO is success!')
            var state = {
              current:{}, // data yang sering diakses; tahun, username dan role
              data:{}, // data user seperti token dll
              err:{}, // error ketika login
              savedUsername:'', // penyimpanan untuk username login
              avatar:'', // foto yang dipakai
              dataPegawai:{}, // data lengkap pegawai
              notifikasi:[],
              lastLogout:null,
              ongoing: false, // memastikan agar tidak tumpuk logout, ongoing logout
              ott: null,
              direct: false,
            }
            state.data.token = data.token
            state.data.direct = true
            store.commit('user/SET_TOKEN', data.token )
            store.commit('settings/SET_SITE', data.settings)
            store.commit('user/SET_DIRECT', true )
            state.data.tokenRefresh = data.tokenRefresh
            state.current = {'username': data.username, 'year': data.tahun, 'role':data.roles[0], 'roles':data.roles}
            state.data.username = data.username
            state.data.tahun = data.tahun
            store.commit('user/SET_ROLE', data.roles )
            store.commit('user/SET_UNAME', data.username )
            store.commit('user/SET_TAHUN', data.tahun )
            // store.commit('user/SET_UNAME', username )
            // store.commit('user/SET_TAHUN', tahun )
            store.commit('user/SET_CURRENT', state.current )
            state.data = _.clone(data)
            state.err = {}

            var pegawai = null
            axios.post(url_peg, {'nip': username, 'tahun': tahun, 'token': direct_token}, config).then(response => {
              pegawai = response.data.pegawai[0]
              // local.setLocal("plt_id", response.data.pegawai[0].plt_id)
              local.setLocal("kolok", response.data.pegawai[0].kolok)
              var plt_name = JSON.parse(pegawai.plt_name)
              local.setLocal("plt_names", plt_name)
              var plt_id = JSON.parse(pegawai.plt_id)
              local.setLocal("plt_ids", plt_id)
              let user = store.state.user
              if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
                pegawai.jabatan = user.current.role
                pegawai.jabatan_penyetaraan = "-"
                pegawai.perangkat_daerah = "Pemerintah Kota Bandung"
                pegawai['perangkat daerah'] = "Pemerintah Kota Bandung"
                pegawai.nama = user.current.role
                pegawai.nip = user.current.username
              }else{
                user.current.username = pegawai.nip
              }
              state.dataPegawai = pegawai
              if (pegawai.foto.match('undefined')){
                this.avatarPhoto = process.env.VUE_APP_IMG_URL + pegawai.nip +".jpeg"
              } else {
                this.avatarPhoto = pegawai.foto.replace(/\.jpg$/gi,'.jpeg')
              }
              local.setLocal("dataASN", pegawai)
              local.setLocal("avatarPhoto", this.avatarPhoto)
              store.commit('user/SET_AVATAR', this.avatarPhoto)
              store.commit('user/SET_TOKEN', direct_token)
              store.commit('user/DATA_PEGAWAI', _.clone(pegawai))

              setTimeout(function(){
                console.log('Direct from SSO is success!')
                router.push('/ganti-peran')
              },1000)

            }).finally(()=>{
              local.setLocal("get_data_pegawai", true)
            }).catch(err => {
              var error = err.message ? err.message : err.response.data.message
              this.loadingEl = -1
              store.commit('snackbar/setSnack',{message: error, color:'error'})
            })
            // if(pegawai == null){
            //   DashboardService.pegawai2(username, tahun, direct_token).then(response => {
            //     console.log('Direct from SSO and get data pegawai is success!')
            //     local.setLocal("get_data_pegawai", true)
            //     store.commit('user/SET_TOKEN', direct_token )
            //     pegawai = response.data.pegawai[0]
            //   })
            // }
            // local.setLocal("plt_id", response.data.pegawai[0].plt_id)
            // local.setLocal("kolok", response.data.pegawai[0].kolok)
            // let user = store.state.user
            // if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas")){
            //   pegawai.jabatan = user.current.role
            //   pegawai.jabatan_penyetaraan = "-"
            //   pegawai.perangkat_daerah = "Pemerintah Kota Bandung"
            //   pegawai['perangkat daerah'] = "Pemerintah Kota Bandung"
            //   pegawai.nama = user.current.role
            //   pegawai.nip = user.current.username
            // }else{
            //   user.current.username = pegawai.nip
            // }
            // state.dataPegawai = pegawai
            // if (pegawai.foto.match('undefined')){
            //   this.avatarPhoto = process.env.VUE_APP_IMG_URL + pegawai.nip +".jpeg"
            // } else {
            //   this.avatarPhoto = pegawai.foto.replace(/\.jpg$/gi,'.jpeg')
            // }
            // local.setLocal("dataASN", pegawai)
            // local.setLocal("avatarPhoto", this.avatarPhoto)
            // store.commit('user/SET_AVATAR', this.avatarPhoto)
            // // store.commit('DATA_PEGAWAI', _.clone(pegawai))
            // store.commit('user/DATA_PEGAWAI', _.clone(pegawai))
            // console.log('Await request: '+pegawai);
            // setTimeout(function(){
            //   console.log('Direct from SSO is success!')
            //   router.push('/ganti-peran')
            // },1000)
          }).catch(err => {
            console.log('err: '+ err)
            this.errorMessage = err.message
            this.showError = true
            this.loading = false
          })
        }catch(err){
          console.log(err)
        }
      }
    },
    async componentDidMount(){
      //Handle redirect SSO
      const queryString = window.location.href;
      const urlParams = new URL(queryString)
      // const ott = urlParams.searchParams.get('ott')
      const trimUrlParams = urlParams.hash.slice(2)
      const myArrayOTT = trimUrlParams.split("=")
      // const ott = myArrayOTT[1]

      var API_URL = ''
      if (process.env.VUE_APP_SERVER === 'local'){
        API_URL = ''
      }else {
        API_URL = process.env.VUE_APP_API_URL
      }
      // let url_verify = API_URL+'api/sso/auth/verify'
      local.setLocal("login_via_ga", true)
      let url_peg = API_URL+'page1/pegawai'
      try{
          // let [request1Response] = await Promise.all(
          //   [
          //   // axios.get(url_verify+`?&ott=`+ott)
          //   axios.post(url_verify,{ott:ott})
          //   ])
          const ott = myArrayOTT[1].split("&")[0]
          const tahun = myArrayOTT[2]
          var state = {
            current:{}, // data yang sering diakses; tahun, username dan role
            data:{}, // data user seperti token dll
            err:{}, // error ketika login
            savedUsername:'', // penyimpanan untuk username login
            avatar:'', // foto yang dipakai
            dataPegawai:{}, // data lengkap pegawai
            notifikasi:[],
            lastLogout:null,
            ongoing: false, // memastikan agar tidak tumpuk logout, ongoing logout
            ott: null,
            direct: false,
          }
          var {request1Response} = await Promise.all(resolve => {
              SSOService.verifyOTT(ott, tahun)
              .then( response => {
                let data = response.data
                request1Response = data
                local.setLocal("login_via_ga", true)
                state.data.token = data.token
                state.data.direct = true
                store.commit('user/SET_TOKEN', data.token )
                store.commit('settings/SET_SITE', data.settings)
                store.commit('user/SET_DIRECT', true )
                state.data.tokenRefresh = data.tokenRefresh
                state.current = {'username': data.username, 'year': data.tahun, 'role':data.roles[0], 'roles':data.roles}
                state.data.username = data.username
                state.data.tahun = data.tahun
                store.commit('user/SET_ROLE', data.roles )
                store.commit('user/SET_UNAME', data.username )
                store.commit('user/SET_TAHUN', data.tahun )
                store.commit('user/SET_CURRENT', state.current )
                state.data = _.clone(data)
                state.err = {}
                resolve(request1Response)
              }).catch( e => {
                console.error('error=>', e)
                try {
                  resolve(e.response.data)
                } catch(e){
                  resolve(e.response)
                }
              })
            }
          )

          var data = request1Response.data
          var config = {
            headers:{
              "x-access-token": data.token,
              "Content-Type": "application/json"
            }
          }
          const getPegawai = await axios.post(url_peg, {'username': data.username, 'tahun': data.tahun, 'token': data.token}, config);
          console.log('Await request: '+getPegawai);
          console.log('Direct from SSO is success!')
      }
      catch(err){
          console.log(err)
      }
    }
  }
}
</script>

<style lang="css" scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #aaa;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>
